.partners_list {
  font-family: 'Poppins', sans-serif;

  .container-fluid {
    width: 100%;
    padding: 0;
    margin: 0%;
  }

  .container {
    width: 68%;
    background-color: rgb(243, 243, 243);
    margin: 42px auto;
    padding: 12px;
    padding-left: 40px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }
  ul {
    padding-left: 12px;
  }
  h5 {
    font-weight: 600;
    font-size: 22px;
    text-align: left;
  }

  h5 > span {
    border-bottom: 2px solid rgb(53, 53, 53);
  }
}
