@import "node_modules/bootstrap/scss/bootstrap";

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "./assets/css/NotFound.scss";
@import "./assets/Thankyou/css/Thankyou.scss";
@import './assets/css/Modal.scss';
@import "./assets/css/privacy_policy.scss";
@import "./assets/css/cookies-policy.scss";
@import "./assets/css/terms_of_use.scss";
@import "./assets/css/SPR_V3/chat.scss";
@import "./assets/css/SPH_CDV3/chat.scss";
@import "./assets/css/SPH_CDV8_4_1/chat.scss";

@import "./assets/css/Partners_list/index.scss";
