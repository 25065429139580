/*
*******************************************
Template Name: RISEUP USA
Author: SHAREEF
Date: 27-04-2023
Template By: Vandalay Designs
Copyright 2020-2023 Vandalay Business Solution

* This file contains the styling for the TMS Legal, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************

*/

/*
============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - IMPORTS 
03 - MIXIN 
04 - FOOTER 

05 - MEDIA QUERY
 
============================================
============================================

*/

  /*================================================
01 - Sass Variables
==================================================*/
  $colorPrimary: #0070ED;
  $colorScondary: #00BCF9;
  $colorBlack: #000;
  $colorWhite: #fff;
  $colorGray: #595959;
  $colorlightGray: #ebebeb;

  $color1: #ff512f;
  $color2: #e8feff;
  $color3: #04df3d;

  /*================================================
02 - IMPORTS
==================================================*/
  @import "bootstrap/scss/bootstrap";

  /*FONTS*/

  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

  /*================================================
04 - MIXIN
==================================================*/
  @mixin bg-overlay {

    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#006ded+1,006ded+98&0.4+3,0.4+100 */
    background: -moz-linear-gradient(top, rgba(0, 109, 237, 0.4) 1%, rgba(0, 109, 237, 0.4) 3%, rgba(0, 109, 237, 0.4) 98%, rgba(0, 109, 237, 0.4) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0, 109, 237, 0.4) 1%, rgba(0, 109, 237, 0.4) 3%, rgba(0, 109, 237, 0.4) 98%, rgba(0, 109, 237, 0.4) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0, 109, 237, 0.4) 1%, rgba(0, 109, 237, 0.4) 3%, rgba(0, 109, 237, 0.4) 98%, rgba(0, 109, 237, 0.4) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#66006ded', endColorstr='#66006ded', GradientType=0);
    /* IE6-9 */

  }

  @mixin bnt-style {
    background: $colorPrimary;
    color: $colorWhite;
    padding: 15px 60px;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    transition: ease-in 0.4s;
    outline: navajowhite;
    border: 0;
    letter-spacing: 1px;
    font-size: 16px;

    &:hover,
    &:focus {
      color: $colorWhite;
      transition: ease-in 0.4s;
      transform: translateY(-5px);
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      background: $colorScondary;

    }
  }

  @mixin clearfix() {
    &::after {
      display: block;
      clear: both;
      content: "";
    }
  }

  /*************************/
  .clearfix {
    @include clearfix;
  }

  body {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
  }

  p {
    font-size: 14px;
  }

  ul,
  li {
    padding: 0;
    margin: 0;
  }

  .text-justify {
    text-align: justify
  }

  .com_bnt {
    @include bnt-style;
  }

  .curve-img {
    img {
      border-radius: 10px;
    }
  }

  .sp-line {
    position: relative;

    &::before {
      background: $colorScondary;
      height: 5px;
      width: 100%;
      position: absolute;
      top: 0px;
      content: "";
    }
  }

  .pd-80 {
    padding: 80px 0;
  }

  .header {
    z-index: 99;
    width: 100%;
    top: 0;
   
    &.home-bg {
      position: absolute;
      top: 29px;

      .nav-link {
        color: $colorWhite;

        &.active {

          color: $colorWhite !important;

        }
      }
    }

    .logo {
      padding: 5px 0;
      display: block;

    }

    .navbar-nav {

      .nav-link {
        text-transform: uppercase;
        padding: 20px 30px;
        font-weight: normal;
        position: relative;
        transition: ease-in 0.5s;
        margin: 0 5px;

        &.active {
          font-weight: 600;
          color: $colorPrimary;

          &::after {
            opacity: 1;

          }
        }

        &::after {
          background: $colorPrimary;
          height: 3px;
          position: absolute;
          bottom: 0;
          content: "";
          width: 100%;
          left: 0;
          opacity: 0;
          transition: ease-in 0.5s;

        }

        &:hover {
          color: $colorPrimary;

          &::after {
            transition: ease-in 0.5s;
            opacity: 1;
          }


        }



      }
    }

  }


  footer {
    background: $colorWhite;
    padding: 40px 0;

    .copy {
      font-size: 12px;
    }

    ul {
      li {
        display: inline-block;
        padding-left: 10px;

        &:first-child {
          padding-left: 0;
        }

        a {
          color: $colorBlack;

          &:hover {

            transition: all ease 0.5s;
            color: $colorScondary;

          }
        }
      }
    }

    .sp-line {

      &::before {

        top: -40px;

      }
    }
  }

  .img-box {
    img {
      width: 100%;
    }
  }

  .strip-lp {
    background: $colorScondary;
    color: $colorWhite;

    img {
      margin-right: 10px;
    }
  }

  .copl {
    background: none;
    background: url(../../img/SPR_V3/hamburger-menu.png);
    background-repeat: no-repeat;
    background-size: 25px;
    background-position: center;
  }

  /*================================================
05 - MEDIA QUERY
==================================================*/


  @media screen and (max-width: 960px) {
    .header {
      .navbar-toggler {
        margin-top: 8px;
        border: 1px solid $colorWhite;
      }

      .navbar-nav {

        .nav-link {
          padding: 15px 10px
        }
      }
    }

    .com_bnt {
      padding: 15px 30px;
    }

    .pd-80 {
      padding: 40px 0;
    }
  }


  @media screen and (max-width: 760px) {
    .sp-line {
      &::before {
        left: 0;
      }
    }

    .header {
      .navbar-nav {
        .nav-link {
          &::after {
            height: 1px;
          }
        }
      }
    }

    .com_bnt {
      padding: 10px 50px;
      font-size: 14px;
    }

    footer {
      .footer-logo {
        img {
          width: 200px;
        }
      }
    }

    .strip-lp {
      font-size: 12px;
    }
  }



  @media screen and (max-width: 540px) {

    .inner-banner {
      &::after {
        height: 29px;
      }
    }

    .header {
      .logo {
        img {
          width: 120px;
        }
      }
    }

    p {
      font-size: 13px;
    }

    .strip-lp {
      font-size: 10px;
    }

    footer {
      .footer-logo {
        img {
          width: 120px;
        }
      }

      ul {
        li {
          a {
            font-size: 12px;
          }
        }
      }
    }
  }
