.SPH_CDV8_4_1{
    .extra-padding-SPR_V8_4C{
          padding-right: 0px !important;
          padding-left: 0px !important;
          padding-bottom: 12px !important;
          padding-top: 12px !important;
          background: #ffffff00 !important;
          // border-radius: 50px !important;
  
          .action-btn{
            margin-right: 0px;
            padding-right: 35px !important;
            padding-left: 35px !important;
            // background: none !important;
            &.big-button{
              padding-right: 60px !important;
              padding-left: 60px !important;
            }
            &.pulse_blue {
              animation: pulse-animation-blue 1.5s infinite !important;
            }
          
            @keyframes pulse-animation-blue {
              0% {
                box-shadow: 0 0 0 0px rgba(32, 154, 224, 0.616);
              }
              100% {
                box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
              }
            }
          }
    }
  //header css
  .content-space{
    padding-top: 30px;
  
    @media only screen and (max-width: 480px) {
      padding-top: 60px !important;
  }
  }
  
      .Seguro__Latinos {
            font-family: 'Poppins', sans-serif !important;
            
            .main_flic {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
    
                .inner_content {
                    padding-top: 26px;
    
                    @media only screen and (max-width: 480px) {
                        padding-top: 22px;
                    }
    
                    h2 {
                        font-size: 38px;
                        font-weight: 500;
    
                        @media only screen and (min-width: 481px) and (max-width: 768px) {
                            font-size: 32px !important;
                        }
    
                        @media only screen and (min-width: 0px) and (max-width: 480px) {
                            font-size: 22px !important;
                        }
                    }
    
                    //h2
                }
                .highlight {
                  background-color: rgb(247, 247, 175);
                }
                
                .redUnderline {
                  position: relative;
                  display: inline-table;
                }
                 
            
                .redUnderline::after {
                  content: '';
                  position: absolute;
                  left: 0;
                  bottom: -2px; 
                  width: 100%;
                  height: 10px; 
                  background-color: red;
                  border-radius: 4px; 
                }
                //inner_content
                .inner_bg {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding-top: 20px;
    
                    @media only screen and (min-width: 481px) and (max-width: 768px) {
                        flex-direction: column;
                    }
    
                    @media only screen and (max-width: 480px) {
                        padding: 16px 0;
                        flex-direction: column;
                    }
    
                    .bg_img1,
                    .bg_img2 {
                        width: 100%;
                    }
    
                    p {
                        font-size: 15px;
                    }
    
                    /*** VARIABLES ***/
                    $black: #1d1f20;
                    $blue: #83e4e2;
                    $green: #a2ed56;
                    $yellow: #fddc32;
                    $white: #fafafa;
    
                    /*** EXTEND ***/
                    /* box-shadow */
                    %boxshadow {
                        box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05), 0 0.5rem 1.125rem rgba(75, 0, 0, 0.05);
                    }
    
                    ol.gradient-list {
                        // counter-reset: gradient-counter;
                        list-style: none;
                        margin: 1.75rem 0;
                        padding-left: 1rem;
    
                        @media only screen and (max-width: 480px) {
                            margin: 1.8rem 0;
                        }
    
                        >li {
                            @extend %boxshadow;
                            background: white;
                            // border-radius: 0 0.5rem 0.5rem 0.5rem;
                            // counter-increment: gradient-counter;
                            padding: 7px 30px 7px 35px;
                            position: relative;
                            font-size: 15px;
                            background: url('../../img/SPH_CDV8_4_1/li.png') no-repeat left center;
                            border-bottom: dashed 1px #a39e9e;
    
                            @media only screen and (max-width: 480px) {
                                font-size: 15px;
                                padding: 7px 30px 7px 35px;
                            }
    
                            +li {
                                margin-top: 0px;
                            }
                        }
                    }
    
                }
            }
        }
  
        //end header css
    $colorBlack:#000;
    body {
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
    }
    
    
     header {
      background:  #eee;
     }
    
    .mrgn-btm{
      margin-bottom: 180px !important;
    }
    
    
    .chat-section {
      width: 100%;
      float: left;
      .chat-wrap {
        max-width: 450px;
        margin: 0 auto;
      }
      
      .chat-box-inner {
        position: relative;
        z-index: 5px;
        min-height: 400px;
        padding: 10px;
        overflow: hidden;
        .row-chart-message {
          font-family: Arial, Helvetica, sans-serif;
          .chater-prof {
            display: flex;
            align-items:flex-end;
            max-width: 90%;
            .text-msg {
              background: #e5e7eb;
              padding: 10px 15px;
              margin:5px 0 0 0;
              display: inline-block;
              border-radius: 5px;
              font-size: 15px;
              cursor: pointer;
               
    
              &.btn-spc {
                padding: 20px 10px;
              }
            }
    
            .phone {
              font-size: 16px;
              padding:10px 20px;
             }
             
    
          }
         
          
         
          .msg-loader {
            img {
              height: 15px;
              
            }
          }
        }
        .supporter-dp {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: #ddd;
          display: block;
          margin-right: 10px;
          text-align: center;
          border: 1px solid #ddd;
          overflow: hidden;
          img {
     
            height: 38px;
           
            
          }
        }
        .div_width1{
          width:88%;
        }
        .sel-option {
          &.sel_wdth1 {
            display: flex;
            justify-content: end;
            width: 90%;
  
            .action-btn{
              max-width: 87%;
            }
          }
          
          .action-btn {
            background:   #3b82f6;
            padding:15px 40px  ;
            color: #fff;
            -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            border-radius: 50px;
            font-weight: bold;
            &.answ-bnt {
              padding: 15px 20px;
            }
           
          }
        }
    
        .client-replay {
          text-align: right;
          margin-top: 10px;
    
          .client-replay-vpx {
            display: flex;
            justify-content: end;
            align-items: center;
              .dp-client {
                @extend .supporter-dp;
                order: 2;
                margin-right: 0;
                margin-left: 10px;
                
              }
    
          }
      }
      }
    
     }
    
     
  
    
    // .text-message-item {
    //   display: none !important;
    // }
    
    .slide-top {
        -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    
    @-webkit-keyframes slide-top {
        0% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
        }
        100% {
          -webkit-transform: translateY(-5px);
                  transform: translateY(-5px);
        }
      }
      @keyframes slide-top {
        0% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
        }
        100% {
          -webkit-transform: translateY(-5px);
                  transform: translateY(-5px);
        }
      }
    .body-inner {
      height: 100vh;
    }
    .foot{
      color: #0D6EFD;
        text-decoration: underline;
    }
    
    footer {
      float: left;
      width: 100%;
      padding-top: 50px;
      p {
        font-size: 14px;
      }
      a {
        color: $colorBlack;
        text-decoration: none;
      }
    }
    .sub-text {
      font-size: 22px;
    }
    
    .text1 {
      text-align: center;
     font-size: 13px;
     color: #ffffff;
     background-color: #cd0404;
     position: fixed;
     width: 100%;
     z-index: 99;
    }
    .text2 {
      font-size: 32px;
      color: #4b5563;
      font-family: 'Roboto', sans-serif;
    }
    .logo-div {
      padding-top: 3rem;
    }
    .support-online {
       span {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        box-shadow: 0px 0px 1px 1px #0000001a;
        display: inline-block;
        position: relative;
        top: 3px;
         background: #22c55e;
      }
      .team {
        background: #e7e8eb;
        border-radius: 5px;
        border: 2px solid #3b82f6;
        max-width: 350px;
        font-size: 14px;
          margin: 0 auto;
          .avtrtd {
            vertical-align: top;
          }
        .supporter-dp {
          width: 80px;
          img {
            height: 38px;
            border-radius: 50%;
          }
        }
      }
       
      
    
      .pulse {
        animation: pulse-animation 1.5s infinite;
      }
      
      @keyframes pulse-animation {
        0% {
          box-shadow: 0 0 0 0px rgba(162, 221, 122, 0.616);
        }
        100% {
          box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
        }
      }
      
      
    }
    
    @media (min-width:1025px) {
      .top_fix_stcik {
        line-height: 18px;
        p {
            font-size: 16px !important;
            margin-bottom: 0px;
        }
      }
    
    }
      @media screen and (max-width: 760px) {
           
      
        .chat-section {
          .chat-box-inner {
            .sel-option {
              .action-btn {
                // padding: 15px 30px;
              }
            }
          }
        }
        .logo-div {
          padding-top: 4.5rem;
      }
    
        .text1 {
          font-size: 14px;
        }
        .text2{
          font-size: 19px;
      
        }
        .sub-text {
          font-size: 16px;
        }
    
        .top_fix_stcik {
          background: #f30000;;
          color: #fff;
          line-height: 18px;
          p {
              font-size: 13px !important;
              margin-bottom: 0px;
          }
      }
        
      }
    
    
    
    }
    
    
    .btn-anime{
      box-shadow: 0 0 0 0 rgba(#5a99d4, .5);
      -webkit-animation: btn_anime_glowing 1.5s infinite;
      animation: btn_anime_glowing 1.5s cubic-bezier(0.57, 0.31, 0.36, 1) infinite;
    
    }
    
    @keyframes btn_anime_glowing {
      0% {
        transform: scale(.9);
      }
      70% {
        transform: scale(1);
        box-shadow: 0 0 0 30px rgba(#5ad46e, 0);
      }
        100% {
          transform: scale(.9);
        box-shadow: 0 0 0 0 rgba(#5ad46e, 0);
      }
    }
    
    
    .btn_reClaim{
      width: 85%;
      background: #00a700;
      color: #fff;
      font-weight: bold;
      border: 0;
      
      &:hover{
        background: #007200;
      }
    }
    
      @media screen and (max-width: 500px) {
        
  
        .SPH_CDV8_4_1{
          .Seguro__Latinos {
            .main_flic{
              .redUnderline{
                display: inline-table;
  
                &::after {
                  content: '';
                  position: absolute;
                  left: 0;
                  bottom: -6px;
                  width: 100%;
                  height: 6px;
                  background-color: red;
                  border-radius: 4px;
  
              }
              }
            } 
          }
        } 
      }
          @media (max-width: 475px) {
            .SPR_V8_1 {
              .chat-section {
                .chat-box-inner {
                  .sel-option {
                    &.sel_wdth1 {
                      display: flex;
                      justify-content: end;
                      width: 90%;
      
                      .action-btn {
                        max-width: 82% !important;
                      }
                    }
                  }
                }
      
              }
      
            }
      
          }